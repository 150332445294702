
<div class="layout-container">
    <div class="overlay"></div>

    <div id="main">
        <div id="container-1" class="container">
            <!-- NAVBAR: Left -->

            <ng-container *ngTemplateOutlet="leftNavbar"></ng-container>
            <!-- / NAVBAR: Left -->

            <div id="container-2" class="container invisible-buttons">
                <ng-container *ngTemplateOutlet="topNot"></ng-container>

                <!-- TOOLBAR: Below fixed -->
                <ng-container *ngTemplateOutlet="toolbar"></ng-container>
                <!-- / TOOLBAR: Below fixed -->
                <div id="container-3" class="container" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{
                        suppressScrollX: true,
                        updateOnRouteChange: true
                    }">
                    <!-- <div class="loader" (click)="logui($event)">
                        HELLO
                    </div> -->
                    <!-- CONTENT -->
                    <content></content>
                    <!-- / CONTENT -->
                </div>
            </div>
        </div>
    </div>

    <!-- SIDE PANEL -->

    <!-- CHAT PANEL -->
    <!-- <fuse-sidebar name="chatPanel" position="right" class="chat-panel right-chat-panel"
                      [folded]="true" [foldedWidth]="70" [foldedAutoTriggerOnHover]="false"
                      lockedOpen="gt-md"
                      *ngIf="!fuseConfig.layout.sidepanel.hidden" >
            <chat-panel></chat-panel>
        </fuse-sidebar> -->
    <!-- / CHAT PANEL -->

    <!-- / SIDE PANEL -->

    <!-- QUICK PANEL -->
    <fuse-sidebar name="quickPanel" position="right" class="quick-panel" style="overflow: hidden;height: 100%;   width: max-content !important;    ">
        <quick-panel style="height: 100%;"></quick-panel>
    </fuse-sidebar>
    <!-- / QUICK PANEL -->

    <!-- ----------------------------------------------------------------------------------------------------- -->
    <!-- @ PARTIALS

         TOOLBAR -->
    <ng-template #topNot>
        <!-- <tutorial  [ngClass]="[
            fuseConfig.layout.toolbar.position,
            fuseConfig.layout.toolbar.background
        ]"></tutorial> -->
        <top-notification [@slideInOut] (close)="setNotToDefault()" *ngIf="topNotConfig && topNotConfig.show" [action]="topNotConfig.action" [message]="topNotConfig.message" >
        </top-notification>
    </ng-template>

    <!-- / TOOLBAR -->
    

    <ng-template #toolbar>
              <!-- <tutorial  [ngClass]="[
                fuseConfig.layout.toolbar.position,
                fuseConfig.layout.toolbar.background
            ]"></tutorial> -->
        <toolbar *ngIf="!fuseConfig.layout.toolbar.hidden" [ngClass]="[
                fuseConfig.layout.toolbar.position,
                fuseConfig.layout.toolbar.background
            ]"></toolbar>
        <app-pop-up class="app-pop-up"></app-pop-up>
    </ng-template>
    <!-- / TOOLBAR -->

    <!-- LEFT NAVBAR -->
    <ng-template #leftNavbar>
            <ng-container *ngIf="(!editingEvent || innerWidth < 1099)">
            <fuse-sidebar name="navbar" class="navbar-fuse-sidebar" style="max-width: 280px;" [folded]="fuseConfig.layout.navbar.folded"
            lockedOpen="gt-md" *ngIf="!fuseConfig.layout.navbar.hidden">
            <navbar [variant]="fuseConfig.layout.navbar.variant" class="left-navbar"
            [ngClass]="fuseConfig.layout.navbar.background"></navbar>
            </fuse-sidebar>
        </ng-container>
    </ng-template>
    <!-- / LEFT NAVBAR -->
</div>

<app-paiement-print class="printer" [data]="printerData" *ngIf="printer === 'pp'"></app-paiement-print>
<app-print class="printer" [data]="printerData" *ngIf="printer === 'p'"></app-print>
<app-planning-print class="printer" [data]="printerData" *ngIf="printer === 'ppl'"></app-planning-print>
<agency-invoice-print *ngIf="printer === 'aip'" class="printer" [data]="printerData"></agency-invoice-print>
<recu-agence-payment size="A5" *ngIf="printer === 'par'" class="printer print-recu" [data]="printerData"></recu-agence-payment>
export const environment = {
    uriG: "https://testenv.autoecoleplus.tn",
    urlFront: "https://testenv.autoecoleplus.tn",
    urlSocket: "https://testenv.autoecoleplus.tn/Agence", //"http://192.168.0.106:3005/Agence"
    urlRealTime: "https://testenv.autoecoleplus.tn/real-time/api", //"http://app.autoecoleplus.tn/real-time/api"
    urlRealTimeLocal: "https://testenv.autoecoleplus.tn/", //"http://app.autoecoleplus.tn/real-time/api"
    paymeeLink: "https://app.paymee.tn/",
    paymeeToken: "d021c0fd01606f026d35b22719f754a4c2f384f8",
    localPMVendor:1825,
    localPMToken:"34286b226bc3397731a3e95e72f3832e9da5c074",
    localPMLink:"https://sandbox.paymee.tn/",
    vendor:15917,
    production: true,
    hmr: false,
    soloLinkingUrl: "https://testlinkingsolo.autoecoleplus.tn"
};


//import { TutorialComponent } from "./layout/components/tutorial/tutorial.component";
import { FormsModule } from "@angular/forms";
import { ErreurService } from "./main/authentification/erreur.service";
import { OverlayModule } from "@angular/cdk/overlay";
//import { SocketIoModule, SocketIoConfig } from "ngx-socket-io";

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);

//import mobile
import { DeviceDetectorModule } from "ngx-device-detector";

//import { MatFileUploadModule } from "angular-material-fileupload";
import { AuthentificationGuard } from "./main/authentification/authentification.guard";
import { NgModule, APP_INITIALIZER, ErrorHandler, LOCALE_ID } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";
import {
    MatMomentDateModule,
    MomentDateAdapter,
    MAT_MOMENT_DATE_FORMATS,
} from "@angular/material-moment-adapter";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { InMemoryWebApiModule } from "angular-in-memory-web-api";
import { MatCardModule } from "@angular/material/card";
import {
    MatDialogModule,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ToolbarComponent } from "./layout/components/toolbar/toolbar.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";
import {
    FuseProgressBarModule,
    FuseSidebarModule,
} from "@fuse/components";
import { fuseConfig } from "app/fuse-config";
import { AppComponent } from "app/app.component";
import { AppStoreModule } from "app/store/store.module";
import { LayoutModule } from "app/layout/layout.module";
import { TokenInterceptorService } from "./main/authentification/token-interceptor.service";
import { FlexLayoutModule } from "@angular/flex-layout";
import { SuccesSnakBarComponent } from "./main/components/succes-snak-bar/succes-snak-bar.component";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import {
    CalendarModule as AngularCalendarModule,
    DateAdapter,
} from "angular-calendar";


import { ExamResultModule } from "./main/plus/calendar/exam-result/exam-result.module";
import { ErrordialogComponent } from "./main/components/errordialog/errordialog.component";
import { AdminGuard } from "./main/authentification/admin.guard";
import { GlobalService } from "./global.service";

import { AppService } from "./app.service";
import { ErrorsHandler } from "./main/authentification/errors-handler";
import { environment } from "../environments/environment";
import { EventChoiceModule } from "./main/plus/calendar/event-choice/event-choice.module";
import { EventFormModule } from "./main/plus/calendar/event-form/event-form.module";
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS } from "@angular/material/core";
import { MatToolbarModule } from "@angular/material/toolbar";
import { WelcomeComponent } from "./main/authentification/welcome/welcome.component";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { PaymentCdialogModule } from "./main/plus/components/payment-cdialog/payment-cdialog.module";
import { DepenceFormModule } from "./main/plus/finance/depences/depence-form/depence-form.module";
import { VehiculeModule } from "./main/plus/ressources/vehicule/vehicule.module";
import { ExamContuiteResultModule } from "./main/plus/calendar/exam-conduite-result/exam-contuite-result.module";
import { ServiceWorkerModule } from "@angular/service-worker";
import { NoInternetModule } from "./main/no-internet/no-internet.module";
import { LoginGuard } from "./main/authentification/login.guard";
import { CopiedSnackbarComponent } from './main/components/copied-snackbar/copied-snackbar.component';
import { CsvDownloadPopupModule } from "./main/pop-ups/csv-download-popup/csv-download-popup.module";
import { ReservationDialogModule } from "./main/plus/components/reservation-dialog/reservation-dialog.module";
import { ErreurInternetComponent } from "./main/no-internet/erreur-internet/erreur-internet.component";
import { CodeOnlineTrainingFormModule } from "./main/plus/online-services/code-online-training-form/code-online-training-form.module";
import { SetLocationModule } from "./main/pop-ups/set-location/set-location.module";
import { ExamNotificationDialogModule } from "./main/plus/components/exam-notification-dialog/exam-notification-dialog.module";

export function GlobalServiceFactory(provider: GlobalService) {
    return () => provider.load("app module");
}


const appRoutes: Routes = [
    // {
    //     path: "welcome",
    //     component: LandingComponent
    // },

    /* {
        path: "administratoraccess",
        loadChildren: () => import('./main/admin/admin.module').then(m => m.AdminModule),
    }, */


    {
        path: "nointernet",
        component: ErreurInternetComponent
        // loadChildren: () => import('./main/no-internet/no-internet.module').then(m => m.NoInternetModule),
    },

    {
        path: "auth",
        loadChildren:
            () => import('./main/authentification/authentification.module').then(m => m.AuthentificationModule),
        //    canActivate: [LoginGuard],
    },
    // this only to show comapement on payment iframe 
    {
        path: "result",
        loadChildren: () => import('./main/plus/result/result.module').then(m => m.ResultModule),
    },

    {
        path: "",
        loadChildren: () => import('./main/plus/plus.module').then(m => m.PlusModule),
        canActivate: [AuthentificationGuard]
        //canActivateChild:[AuthentificationGuard]
    },



];


@NgModule({
    declarations: [
        AppComponent,
        SuccesSnakBarComponent,
        ErrordialogComponent,
        WelcomeComponent,
       // TutorialComponent,
        CopiedSnackbarComponent,

    ],
    imports: [
        NoInternetModule,
        ReservationDialogModule,
        CsvDownloadPopupModule,
        DeviceDetectorModule,
        MatToolbarModule,
        ExamResultModule,
        ExamContuiteResultModule,
        VehiculeModule,
       // NgxMaterialTimepickerModule,
        PaymentCdialogModule,
        ExamNotificationDialogModule,
        FormsModule,
        DepenceFormModule,
        
        EventFormModule,
        EventChoiceModule,
        CodeOnlineTrainingFormModule,
        SetLocationModule,

        OverlayModule,
        MatCheckboxModule,
        MatDialogModule,
        MatCardModule,
        MatSnackBarModule,
        //MatFileUploadModule,
        FlexLayoutModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, 
            {
                // enableTracing: true,
            relativeLinkResolution: 'legacy',
             //onSameUrlNavigation: 'reload',
            }),
        //SocketIoModule.forRoot(config),
        AngularCalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        // Material moment date module
        MatMomentDateModule,
        // Material
        MatButtonModule,
        MatIconModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        // App modules
        LayoutModule,
        AppStoreModule,
        ExamResultModule,
        ServiceWorkerModule.register('custom-service-worker.js', { enabled: environment.production, registrationStrategy: 'registerImmediately' }),
    ],
    bootstrap: [AppComponent],
    providers: [
        ErreurService,
        //TutorialComponent,
        AdminGuard,
        AuthentificationGuard,
        AppService,
        /*{
            provide: APP_INITIALIZER, useFactory: app_init, deps: [AppService], multi: true
        },*/
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true,
        },
        GlobalService,
        { provide: MAT_DATE_LOCALE, useValue: "fr-FR" },
        {provide: LOCALE_ID, useValue: 'fr' },


        // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
        // `MatMomentDateModule` in your applications root module. We provide it at the component level
        // here, due to limitations of our example generation script.
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: MatDialogRef, useValue: {} },
        { provide: MAT_DIALOG_DATA, useValue: [] },
        {
            provide: APP_INITIALIZER,
            useFactory: GlobalServiceFactory,
            deps: [GlobalService],
            multi: true,
        },
        { provide: ErrorHandler, useClass: ErrorsHandler },
    ],

})

export class AppModule {}

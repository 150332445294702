<div *ngIf="isPopUpVisible" class="pop-up-container" @popUpAnimation (click)="navigateToNotification(notification)">
    <div class="pop-up-container-layout">
        <div class="header">
            <div class="notification-title"><b>اشعار</b></div>

            <div class="close-icon" (click)="closePopUp()"><b>X</b></div>
        </div>
        <div class="notification-content">
            {{ notification?.message || 'لقد وصلك اشعار' }}
        </div>
    </div>
</div>

import { AfterViewInit, ChangeDetectorRef, Component, Input, NgZone, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { LayoutService } from 'app/layout/layout.service';
import {MatTooltipModule} from '@angular/material/tooltip';
import * as moment from 'moment';
import {notificationStrcture} from 'app/models/notification'
import { NotificationsService } from 'app/notifications.service';


@Component({
  selector: 'app-notification-box',
  templateUrl: './notification-box.component.html',
  styleUrls: ['./notification-box.component.scss']
})
export class NotificationBoxComponent implements OnInit {
  emptyNotif: boolean = false;
  @Input() notification: any;
  @Input() selectedTab: any;
  buttonsToShow: any[] =[];
  tagsToShow: any[] = [];
  inputValue: string = '';
  emptyNotificationMessage: string = '';
  body : any;
  notificationStructureCopy =  JSON.parse(JSON.stringify(notificationStrcture));
  bodytest = {
    day: ' day: de 03/09 à 11/08',
    time: ' time: de 08:00 à 09:00',
    duration: ' duration: de 2 à 1'
  };
  
  constructor(
    private notificationsService: NotificationsService,
    private layoutService: LayoutService,
    private router: Router,
    private _fuseSidebarService: FuseSidebarService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if(this.notification?.state === "empty"){
      console.log("it is empty here !",this.notification);
      console.log("this.notificationType" , this.selectedTab)
      if (this.selectedTab == 0) this.emptyNotificationMessage = "Pas de notifications";
      else if (this.selectedTab == 1) this.emptyNotificationMessage = "Pas de notifications de la part des candidats";
      else if (this.selectedTab == 2) this.emptyNotificationMessage = "Pas de notifications de la part de l'équipe";
      else if (this.selectedTab == 3) this.emptyNotificationMessage = "Pas de notifications de la part du système";
      else if (this.selectedTab == 4) this.emptyNotificationMessage = "Pas de notifications pour les examens";
      else this.emptyNotificationMessage = "Pas de notifications";
      
      

    } else {
      this.bodyFormat(this.notification.body);
      console.log(this.notification);
      this.deleteWhatIsAdded();
      this.changeTagContent();
      this.cdr.detectChanges();
    }
    
  }


  bodyFormat(body){
    this.body = body?.[0];
    //body is an object it can be a single object that has "single" as key or multiple.
  }
  

  changeTagContent(){
    if(!this.notificationStructureCopy[this.notification.contentTable]) return null;
    if(!this.notificationStructureCopy[this.notification.contentTable][this.notification.action]) return null;
    this.buttonsToShow = this.notificationStructureCopy[this.notification.contentTable][this.notification.action].toShow;
    this.tagsToShow = this.notificationStructureCopy[this.notification.contentTable][this.notification.action].tags;
    const notifTag = this.notification?.tags?.[0];
    let type =[];
    let updatedTags = [...this.tagsToShow];
    for (let i = 0; i < updatedTags.length ; i++){
      let tag = this.tagsToShow[i]
      if (notifTag?.hasOwnProperty(tag.type)){
        if (tag.type === "editedEvent"){
          type = notifTag[tag.type];
        }else {
          if (this.notification.action === "delete"){}
          if (tag.type === "famille"){
            if (this.notification.action === "delete"){
              this.tagsToShow[i]["label"] = notifTag[tag.type];
            } else {
              console.log("(tag.type):",notifTag[tag.type])
              this.tagsToShow[i]["label"] = notifTag[this.checkWhichFamilly(notifTag[tag.type])];
            }
          } else {
            this.tagsToShow[i]["label"] = notifTag[tag.type];
          }
          console.log("tag[label]",tag["label"]);
          this.cdr.detectChanges();
        }
      }
    };
    updatedTags = JSON.parse(JSON.stringify((this.checkEmptyTags(updatedTags))));
    this.tagsToShow = updatedTags;
    console.log('type:',type);
    if(type.length !== 0){
      const editedEvent = updatedTags.pop();
      for (let i = 0; i < type.length; i++) {
        updatedTags.push({tagType:"editedEvent",tagClass:"editedEvent-tag",matTooltip:"a été modfié",tagContent:type[i]})        
      }
    }
    this.tagsToShow = updatedTags;
    this.cdr.detectChanges();
  }

  checkWhichFamilly(famille):string{
    if (famille === "Véhicules"){return "vehicule"}
    else if (famille === "Candidats"){return "candiateRespFullName"}
    else if (famille === "Personnelles"){return "agentRespFullName"}
    else if (famille === "Locaux"){return "ressource"}
    else if (famille === "Autre"){return "famille"}
    return 
  }

  checkEmptyTags(tags){
    let newtags = []
    for(const tag of tags){
      if (tag.label !== "label"){
        console.log("tags.key:",tags[tag])
        newtags.push(tag);
      }
    }
    tags = JSON.parse(JSON.stringify(newtags));
    return newtags

  }

  deleteWhatIsAdded(){
    this.tagsToShow.forEach((tag)=>{
      delete tag.tagContent;

    })
  }


  markasOpened(notification: any,redirect:boolean = true): void{
    notification.opened = true;    
    this.layoutService
            .notificationOpened(notification.id)
            .subscribe(()=>{
              if(redirect) {
                this.goToPage(notification);
              }
              console.log("notif state after: ",notification.opened);
            });
  }

  goToPage(notification: any) {
    this.notificationOpened(notification);
    const { contentId, contentTable } = notification;
    const navigate = (params: any, url: string): {urls: string[], params?: {} } =>  {
        params = JSON.stringify([params]);
        const listOfURLS = {
            reservation: {
                urls: ['/reservations'],
                params: { reservation: params }
            },

            event: {
                urls: ['/calendar'],
                params: { event: params } 
            },
            paiement: {
                urls: ['/finance/recettes'],
                params: { paiement: params }
            },
            depense: {
                urls: ['/finance/depenses/historique'],
                params: { depense: params }
            },
            Candidat: {
                urls: ['/candidats'],
                params: { candidat: params }
            },
            "follow-up":{
                urls: ['/code/questions'],
                params: {question: JSON.parse(params)[0] }
            }
        }
        return listOfURLS[url] || { urls: ['/'] };
    }
    
    if(contentId !== undefined || contentTable != undefined) {
        const navigationOptions = navigate(contentId, contentTable);
        this.router.navigate(
            navigationOptions.urls,
            { queryParams: navigationOptions.params }
        );
    } else {
        
        this.router.navigate(
            navigate('', contentTable).urls
        )
    }
    
    this._fuseSidebarService.getSidebar("quickPanel").toggleOpen();
  }

  notificationOpened(notification) {
    if(notification.opened === false){
      notification.opened = true;    
    }  
  }
  getDate(item) {
    return moment(item.createdAt).fromNow();
  }

  takeAction(event: MouseEvent){
    event.preventDefault();
    event.stopPropagation();
    // console.log("this button works,", this.computedClass);
  }

  onInputChange(event: Event){
    event.preventDefault();
    event.stopPropagation();
    const input = event.target as HTMLInputElement;
    this.inputValue = input.value;
  }

  eventStop(event: MouseEvent){
    event.preventDefault();
    event.stopPropagation();
  }

}

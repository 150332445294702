<div class="no-notif-box" *ngIf="(this.notification?.state === 'empty')"> {{emptyNotificationMessage}}</div>

<div class="notif-box" *ngIf="!(this.notification?.state === 'empty')">
    <!-- [ngClass]="{'notif-box-opened' : notification.opened}" -->
    <div class="notif-box-inside">
        <div class="notif-content">
            <div class="notif-image">
                <img src="assets/images/avatars/anonymous.jpg" alt="Anonymous Profil Image" class="notif-profil-image">
            </div>
            <div class="notif-content-inside" (click)="markasOpened(notification)">
                <div class="notification-title">
                    <div class="notif-message">
                            <b> {{notification.title}} </b> {{notification.message}}
                    </div>
                    <div class="notif-date">
                        {{getDate(notification)}}
                    </div>
                </div>
                <div class="notif-details" *ngIf="notification.body">
                    <div class="notif-details-inside">
                        <span *ngFor="let item of body | keyvalue">
                            <b *ngIf="item.key !== 'single'">{{item.key}}:</b> 
                            <span [innerHTML]="item.value" ></span>
                            <br>
                        </span>
                        <div *ngIf="tagsToShow.length" class="notif-tag-section">
                            <div class="notif-tag"
                                *ngFor="let tag of tagsToShow"
                                [ngClass]="tag.tagClass"
                                [matTooltip]= "tag.matTooltip"
                            >
                                {{tag.label}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="notif-shortcut-buttons">
                    <div class="reply-box" *ngIf="buttonsToShow?.[0]?.buttonType === 'envoyer'">
                        <input
                            id="myInput"
                            type="text"
                            [value]="inputValue" 
                            (input)="onInputChange($event)"
                            (click)="eventStop($event)"
                            placeholder="repondre..."
                        >
                    </div>
                    <button
                    (click)="eventStop($event)"
                    *ngFor="let button of buttonsToShow"
                    [ngClass]="button.buttonClass"
                    >
                        {{button.buttonType}}
                    </button>
                </div>

            </div>
        </div>
        <div class="notif-icon" (click)="markasOpened(notification,redirect=false)">
            <div *ngIf="!notification.opened" class="notif-dot">
            </div>
        </div>
    </div>
</div>
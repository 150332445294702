import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RealTimeService } from 'app/real-time.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-exam-notification-dialog',
  templateUrl: './exam-notification-dialog.component.html',
  styleUrls: ['./exam-notification-dialog.component.scss']
})
export class ExamNotificationDialogComponent implements OnInit {

  notifications = [
    // {
    //     center_name:'Bardo',
    //     category: 'B',
    //     exam_type: 'Circulation',
    //     date:'2025/03/03',
    //     hours:[
    //       '08:00',
    //       '12:00'
    //     ],
    //     agency_exam_list:{
    //       note:'Examen Med Amine'
    //     },
    //     candidates:[
    //       {
    //         fullname:'amine jendoubi',
    //         eventId:'18'
    //       },
    //       {
    //         fullname:'Bacem Ben Hamed',
    //         eventId:'19'
    //       }
    //     ],
        
    // },
    // {
    //     center_name:'Cijoumi',
    //     category: 'B',
    //     exam_type: 'Circulation',
    //     date:'2025/03/03',
    //     hours:[
    //       '08:00',
    //     ],
    //     agency_exam_list:{
    //       note:'Examen Med Amine'
    //     },
    //     candidates:[
    //       {
    //         fullname:'amine jendoubi',
    //         eventId:'18'
    //       },
    //       {
    //         fullname:'Bacem Ben Hamed',
    //         eventId:'19'
    //       }
    //     ],
    // },
    // {
    //   center_name:'Cijoumi',
    //   category: 'B',
    //   exam_type: 'Circulation',
    //   date:'2025/03/03',
    //   hours:[
    //     '08:00',
    //   ],
    // }
  ]

  notifications$ : Observable<any>; 


  constructor(
    private _realTimeService: RealTimeService,
    public dialogRef: MatDialogRef<ExamNotificationDialogComponent>,
    private router: Router,

      @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.notifications$ = this._realTimeService.examListNotifications
    this._realTimeService.examListNotifications.subscribe({next:val=>console.log(val)})
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  goToCenterDeExamsList(){
    this.router.navigate(['/list-exams']);
    this.dialogRef.close();
  }
  ngOnDestroy(){
    this._realTimeService.resetExamListNotification()
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExamNotificationDialogComponent } from './exam-notification-dialog.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { MatChipsModule } from '@angular/material/chips';



@NgModule({
  declarations: [
    ExamNotificationDialogComponent
  ],
  imports: [
    MatBadgeModule,
    MatDividerModule,
    MatIconModule,
    CommonModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatChipsModule,
    MatButtonModule
  ]
})
export class ExamNotificationDialogModule { }

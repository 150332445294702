import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../environments/environment';
import { BehaviorSubject, catchError, Observable, throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class NotificationsService {

  emptyNotif : boolean;
  private emptyNotifSubject = new BehaviorSubject<boolean>(false);
  emptyNotif$ = this.emptyNotifSubject.asObservable();
  private url = environment.uriG;
  computedClass: string;

  // private notifications: Notification[] = [];
  constructor(
    private http: HttpClient
  ) { }

  openNotification(id): Observable<any> {
    console.log('Opening notification with ID:', id);
    return this.http.get(`${this.url}/api/notification/open/${id}`, { responseType: 'json' })
    .pipe(
      catchError((error) => {
        console.error('Error from server2:', error);
        return throwError('An error occurred while opening the notification.');
      })
    )
  }

  emptyNotification(bool):void{
    this.emptyNotifSubject.next(bool);
    // return this.emptyNotif
  }

  // computeClass(notification) {
  //   console.log("notif working")
  //   // Logic to determine the class
  //   if (notification.contentTable === 'reservation') {
  //     this.computedClass = 'paiement';
  //   } else if (notification.contentTable === 'paiement') {
  //     this.computedClass = 'paiement';
  //   } else {
  //     this.computedClass = 'default-class';
  //   }
  // }
}

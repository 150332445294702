export const notificationStrcture = {
    event:{
        add:{
            toShow:[], //* I don't think there is type of buttons here
            tags:[
                {
                    id:"id",
                    label:"label",
                    type:"candidateFullName",
                    tagClass:"candidateFullName-tag",
                    matTooltip:"Nom du candidat",
                },
                {
                    id:"id",
                    label:"label",
                    type:"agentFullName",
                    tagClass:"agentFullName-tag",
                    matTooltip:"Nom de l'agent",
                },
                {
                    id:"id",
                    label:"label",
                    type:"eventType",
                    tagClass:"eventType-tag",
                    matTooltip:"Type d'evenement",
                },                
            ],
        },
        edit:{
            toShow:[],  //* I don't think there is type of buttons here
            tags:[
                {
                    id:"id",
                    label:"label",
                    type:"candidateFullName",
                    tagClass:"candidateFullName-tag",
                    matTooltip:"Nom du candidat",
                },
                {
                    id:"id",
                    label:"label",
                    type:"agentFullName",
                    tagClass:"agentFullName-tag",
                    matTooltip:"Nom de l'agent",
                },
                {
                    id:"id",
                    label:"label",
                    type:"eventType",
                    tagClass:"eventType-tag",
                    matTooltip:"type d'evenement",
                },
            ],
        },
        delete:{
            toShow:[], //* I don't think there is type of buttons here
            tags:[
                {
                    id:"id",
                    label:"label",
                    type:"candidateFullName",
                    tagClass:"candidateFullName-tag",
                    matTooltip:"Nom du candidat",
                },
                {
                    id:"id",
                    label:"label",
                    type:"eventType",
                    tagClass:"eventType-tag",
                    matTooltip:"type d'evenement",
                },
                {
                    id:"id",
                    label:"label",
                    type:"agentFullName",
                    tagClass:"agentFullName-tag",
                    matTooltip:"Nom de l'agent",
                },
            ],
        },
        confirm:{
            toShow:[], //* I don't think there is type of buttons here
            tags:[
                {
                    id:"id",
                    label:"label",
                    type:"candidateFullName",
                    tagClass:"candidateFullName-tag",
                    matTooltip:"Nom du candidat",
                },
                {
                    id:"id",
                    label:"label",
                    type:"eventType",
                    tagClass:"eventType-tag",
                    matTooltip:"type d'evenement",
                },
                {
                    id:"id",
                    label:"label",
                    type:"agentFullName",
                    tagClass:"agentFullName-tag",
                    matTooltip:"Nom de l'agent",
                },
            ],
        },
        deleteResult:{ //* I don't think there is type of buttons here
            toShow:[],
            tags:[
                {
                    id:"id",
                    label:"label",
                    type:"candidateFullName",
                    tagClass:"candidateFullName-tag",
                    matTooltip:"Nom du candidat",
                },
                {
                    id:"id",
                    label:"label",
                    type:"eventType",
                    tagClass:"eventType-tag",
                    matTooltip:"type d'evenement",
                },
                {
                    id:"id",
                    label:"label",
                    type:"agentFullName",
                    tagClass:"agentFullName-tag",
                    matTooltip:"Nom de l'agent",
                },            ],
        },
        examen:{
            toShow:[],
            tags:[
                {
                    id:"id",
                    label:"label",
                    type:"candidateFullName",
                    tagClass:"candidateFullName-tag",
                    matTooltip:"Nom du candidat",
                },
                {
                    id:"id",
                    label:"label",
                    type:"eventType",
                    tagClass:"eventType-tag",
                    matTooltip:"type d'evenement",
                },
                {
                    id:"id",
                    label:"label",
                    type:"agentFullName",
                    tagClass:"agentFullName-tag",
                    matTooltip:"Nom de l'agent",
                },
            ],
        }
    },
    reservation:{
        add:{
            toShow:[
            ],
            tags:[
                {
                    id:"id",
                    label:"label",
                    type:"candidateFullName",
                    tagClass:"candidateFullName-tag",
                    matTooltip:"Nom du candidat",
                },
                {
                    id:"id",
                    label:"label",
                    type:"eventType",
                    tagClass:"eventType-tag",
                    matTooltip:"type d'evenement",
                },               
            ],
        },
        accept:{
            toShow:[], //* I don't think there is type of buttons here
            tags:[
                {
                    id:"id",
                    label:"label",
                    type:"candidateFullName",
                    tagClass:"candidateFullName-tag",
                    matTooltip:"Nom du candidat",
                },
                {
                    id:"id",
                    label:"label",
                    type:"eventType",
                    tagClass:"eventType-tag",
                    matTooltip:"type d'evenement",
                },
                {
                    id:"id",
                    label:"label",
                    type:"agentFullName",
                    tagClass:"agentFullName-tag",
                    matTooltip:"Nom de l'agent",
                },
            ],
        },
        decline:{
            toShow:[], //* I don't think there is type of buttons here
            tags:[
                {
                    id:"id",
                    label:"label",
                    type:"candidateFullName",
                    tagClass:"candidateFullName-tag",
                    matTooltip:"Nom du candidat",
                },
                {
                    id:"id",
                    label:"label",
                    type:"eventType",
                    tagClass:"eventType-tag",
                    matTooltip:"type d'evenement",
                },
                {
                    id:"id",
                    label:"label",
                    type:"agentFullName",
                    tagClass:"agentFullName-tag",
                    matTooltip:"Nom de l'agent",
                },
            ],
        },
    },
    paiement:{
        add:{
            toShow:[], //* I don't think there is type of buttons here
            tags:[
                {
                    id:"id",
                    label:"label",
                    type:"type",
                    tagClass:"eventType-tag",
                    matTooltip:"type de paiement",
                },
                {
                    id:"id",
                    label:"label",
                    type:"candidateFullName",
                    tagClass:"candidateFullName-tag",
                    matTooltip:"Nom du candidat",
                },
                {
                    id:"id",
                    label:"label",
                    type:"agentFullName",
                    tagClass:"agentFullName-tag",
                    matTooltip:"Nom de l'agent",
                },
            ],
        },
        edit:{
            toShow:[], //* I don't think there is type of buttons here
            tags:[
                {
                    id:"id",
                    label:"label",
                    type:"type",
                    tagClass:"eventType-tag",
                    matTooltip:"type de paiement",
                },
                {
                    id:"id",
                    label:"label",
                    type:"candidateFullName",
                    tagClass:"candidateFullName-tag",
                    matTooltip:"Nom du candidat",
                },
                {
                    id:"id",
                    label:"label",
                    type:"agentFullName",
                    tagClass:"agentFullName-tag",
                    matTooltip:"Nom de l'agent",
                },
            ],
        },
        delete:{
            toShow:[], //* I don't think there is type of buttons here
            tags:[
                {
                    id:"id",
                    label:"label",
                    type:"type",
                    tagClass:"eventType-tag",
                    matTooltip:"type de paiement",
                },
                {
                    id:"id",
                    label:"label",
                    type:"candidateFullName",
                    tagClass:"candidateFullName-tag",
                    matTooltip:"Nom du candidat",
                },
                {
                    id:"id",
                    label:"label",
                    type:"agentFullName",
                    tagClass:"agentFullName-tag",
                    matTooltip:"Nom de l'agent",
                },
            ],
        },
    },
    depense:{
        add:{
            toShow:[], //* I don't think there is type of buttons here
            tags:[
                {
                    id:"id",
                    label:"label",
                    type:"famille",
                    tagClass:"agentFullName-tag",
                    matTooltip:"type de depense",
                },
            ],
        },
        edit:{
            toShow:[], //* I don't think there is type of buttons here
            tags:[
                {
                    id:"id",
                    label:"label",
                    type:"famille",
                    tagClass:"eventType-tag",
                    matTooltip:"type de depense",
                },
            ],
        },
        delete:{
            toShow:[], //* I don't think there is type of buttons here
            tags:[
                {
                    id:"id",
                    label:"label",
                    type:"famille",
                    tagClass:"agentFullName-tag",
                    matTooltip:"type de depense",
                },
            ],
        },
    },
    Candidate:{
        add:{
            toShow:[], //* I don't think there is type of buttons here
            tags:[
                {
                    id:"id",
                    label:"label",
                    type:"eventType",
                    tagClass:"eventType-tag",
                    matTooltip:"type d'evenement",
                },
                {
                    id:"id",
                    label:"label",
                    type:"agentFullName",
                    tagClass:"agentFullName-tag",
                    matTooltip:"Nom de l'agent",
                },
            ],
        },
        edit:{
            toShow:[], //* I don't think there is type of buttons here
            tags:[
                {
                    id:"id",
                    label:"label",
                    type:"eventType",
                    tagClass:"eventType-tag",
                    matTooltip:"type d'evenement",
                },
                {
                    id:"id",
                    label:"label",
                    type:"agentFullName",
                    tagClass:"agentFullName-tag",
                    matTooltip:"Nom de l'agent",
                },
            ],
        },
        delete:{
            toShow:[], //* I don't think there is type of buttons here
            tags:[
                {
                    id:"id",
                    label:"label",
                    type:"eventType",
                    tagClass:"eventType-tag",
                    matTooltip:"type d'evenement",
                },
                {
                    id:"id",
                    label:"label",
                    type:"agentFullName",
                    tagClass:"agentFullName-tag",
                    matTooltip:"Nom de l'agent",
                },
            ],
        },
    },
    "follow-up":{
        message:{
            toShow:[
            ],
            tags:[
                {
                    id:"id",
                    label:"label",
                    type:"candidateFullName",
                    tagClass:"candidateFullName-tag",
                    matTooltip:"Nom du candidat",
                },
            ],
        }
    }
}
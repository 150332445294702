import { NgModule } from "@angular/core";

import { VerticalLayout1Module } from "app/layout/vertical/layout-1/layout-1.module";
import { PopUpComponent } from './components/pop-up/pop-up.component';


@NgModule({
    imports: [VerticalLayout1Module],
    exports: [VerticalLayout1Module],
    declarations: [
    
  ],
})
export class LayoutModule {}
